export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL || 'http://localhost:8082';
export const BASE_WS_URL = process.env.REACT_APP_BASE_WS_URL || 'ws://localhost:9807';

export const API_URL = BASE_API_URL + '/v2';

export const TOKEN = 'token';

export const USER_REDUCER = 'user-reducer';
export const DEAL_REDUCER = 'deal-reducer';

export const EXPORT_JOB = 'EXPORT_JOB';
