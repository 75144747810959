import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {connect, useDispatch} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {API_URL} from "../../utils/consts";
import ErrorComponent from "../../components/error/ErrorComponent";
import {useForm} from "react-hook-form";
import InputWrapper from "../../components/inputWrapper/InputWrapper";
import TextAreaWrapper from "../../components/textAreaWrapper/TextAreaWrapper";
import SelectWithFormHook from "../../components/selectWithFormHook/SelectWithFormHook";
import 'react-datepicker/dist/react-datepicker.css';
import {StyledDropzone} from "../../components/styledDropzone/StyledDropzone";
import {findReplaceString, getBadgeType, getColClass} from "../../utils/helpers";
import fileDownload from "js-file-download";
import {Modal} from 'bootstrap';
import axiosInstance from "../../utils/apiCalls";
import InputMaskWrapper from "../../components/inputWrapper/InputMaskWrapper";
import InputDaDataWrapper from "../../components/inputWrapper/InputDaDataWrapper";
import DetailView from "../../components/deal/DetailView";
import Timeline from "../../components/timeline/Timeline";
import DealVerification from "../../components/dealVerification/DealVerification";
import {isDoneGenerating} from "../../storage/actions/DealActions";
import AccordionCard from "../../components/deal/AccordionCard";

function DealUpdateScreen({user, deal: dealState}) {
    const navigate = useNavigate();
    const dispatcher = useDispatch();

    const {id} = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const [nextStatusId, setNextStatusId] = useState(null)
    const [backStatusId, setBackStatusId] = useState(null)
    const [base64ImageStamp, setBase64ImageStamp] = useState(null)
    const [backButton, setBackButton] = useState({})
    const [nextButton, setNextButton] = useState({})
    const [cancelButton, setCancelButton] = useState({})
    const [deal, setDeal] = useState({})
    const [detail, setDetail] = useState({})
    const [errorForm, setErrorForm] = useState({})
    const [errorList, setErrorList] = useState([])
    const [projectStatuses, setProjectStatuses] = useState([])
    const [isDocumentGenerating, setIsDocumentGenerating] = useState(false)
    const [currentGeneratedDocumentId, setCurrentGeneratedDocumentId] = useState(null)
    const [pwst, setPwst] = useState({
        'frontend:deal:update:card:accordion': false,
    })

    const inputMaskFields = [
        {title: 'inputMask:ru:passport:series', mask: '9999'},
        {title: 'inputMask:ru:passport:number', mask: '999999'},
        {title: 'inputMask:ru:passport:subdivision_code', mask: '999-999'},
        {title: 'inputMask:ru:phone', mask: '+9(999)999-99-99'}
    ];

    const {
        register,
        handleSubmit,
        formState: {errors},
        setValue,
        getValues,
        control,
        setError,
        clearErrors

    } = useForm({
        defaultValues: {},
    })

    useEffect(() => {
        if (id) {
            fetchDeal(id)
        }
    }, [id]);

    useEffect(() => {
        if (dealState.isNeedRefresh && parseInt(id) === dealState.deal_id) {
            fetchDeal(id)
            dispatcher(isDoneGenerating({isNeedRefresh: false}))
        }
    }, [dealState.isNeedRefresh]);


    const fetchDeal = (id) => {
        setIsLoading(true);
        window.scrollTo({
            top: 0,
        })
        axiosInstance.get(API_URL + '/deals/detail', {
            params: {
                id,
            }
        })
            .then(response => response.data)
            .then(response => {
                for (let key in response.data.deal.dealInfo) {
                    setValue("Deal." + key, response.data.deal.dealInfo[key]);
                }
                setDeal(response.data.deal)
                setDetail(response.data.detail)
                setPwst(response.data.pwstSettings)
                setNextStatusId(response.data.nextStatus)
                setBackStatusId(response.data.backStatus)
                setNextButton(response.data.nextButton)
                setBackButton(response.data.backButton)
                setCancelButton(response.data.cancelButton)
                setProjectStatuses(response.data.projectStatuses)

                if (response?.data?.deal?.dealInfo?.sign_image_path) {
                    _getStamp(id)
                }
            })
            .catch(errorResponse => {
                setErrorForm({
                    message: errorResponse.response.data.errors.message,
                    code: errorResponse.response.status
                })
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const _getErrors = (columnName) => {
        if (errors.Deal && columnName in errors.Deal) {
            return errors.Deal[columnName];
        }
    }

    const _getAttributesByName = (name) => {
        const values = getValues();

        for (let attribute in values.Deal) {
            if (attribute === name) {
                return values.Deal[attribute];
            }
        }
    }


    const handleForm = (data) => {
        console.log('handle')
        setErrorList([])
        clearErrors('Deal')
        setIsLoading(true)
        axiosInstance.post(API_URL + '/deals/update', {
            DynamicModel: data.Deal,
            Deal: {
                project_workflow_status_id: nextStatusId,
            }
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            params: {
                id: deal.id,
            }
        })
            .then(response => response.data)
            .then(() => fetchDeal(deal.id))
            .catch(errorResponse => {
                let errors = []
                errorResponse?.response?.data?.errors?.map(error => {
                    setError(`Deal.${error.name}`, {
                        type: "random",
                        message: error.message,
                    })
                    errors.push(error.message)
                })
                setErrorList(errors);
            }).finally(() => setIsLoading(false))
    }

    const cancel = () => {
        setIsLoading(true)
        axiosInstance.post(API_URL + '/deals/update', {
            Deal: {
                project_workflow_status_id: backStatusId,
            }
        }, {
            headers: {
                Authorization: `Bearer ${user.token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            params: {
                id: deal.id,
            }
        })
            .then(response => response.data)
            .catch(errorResponse => {
                if (errorResponse?.response?.data) {
                    errorResponse.response.data.errors.map(error => {
                        setError(`Deal.${error.name}`, {
                            type: "random",
                            message: error.message,
                        })
                    })
                }
            }).finally(() => fetchDeal(deal.id))
    }

    const _getValueForDropdown = (options, fieldName) => {
        for (let option in options) {
            if (options[option].value === _getAttributesByName(fieldName)) {
                return options[option];
            }
        }
    }

    const _renderRows = (rows) => {
        return rows.map(row => {
            return (
                <div className={'row'} key={row.id}>
                    {_renderCols(row.col)}
                </div>
            );
        })
    }

    const _getStamp = (dealId) => {
        axiosInstance.get(API_URL + '/deal/verification/stamp', {
            params: {
                id: dealId,
            }
        })
            .then(response => {
                setBase64ImageStamp(response.data.stamp)
            })
            .catch(errorResponse => {
                console.log(errorResponse)
            })
    }

    const _downloadFile = (url, button) => {
        setIsDocumentGenerating(true);
        setCurrentGeneratedDocumentId(button.id)

        let buttonUrl = button.projectButton.path_url;
        let templateType = buttonUrl.split('template_type=')[1];

        axiosInstance.get(API_URL + url, {
            responseType: 'blob',
        })
            .then(response => fileDownload(response.data, deal.contractCode + '_' + templateType + '.pdf'))
            .finally(() => {
                setIsDocumentGenerating(false);
                setCurrentGeneratedDocumentId(null);
            })
    }

    const renderInputMaskWrapper = (field, mask) => (
        <InputMaskWrapper
            isVisible={field.type?.title !== 'hidden'}
            errors={_getErrors(field.projectField.name)}
            key={field.id}
            id={field.projectField.name}
            register={register}
            readOnly={field.type?.title === 'readonly'}
            disabled={field.type?.title === 'disabled'}
            setValue={setValue}
            isDatetime={false}
            mask={mask}
            label={field.projectField.label}
            isRequired={true}
        />
    );

    const _renderCols = (cols) => {
        return cols.map(col => {
            return (
                <div className={getColClass(col)} key={col.id}>
                    {col.fields.map(field => {
                        if (field.projectField.widget[0]?.fieldWidget?.title === 'DaData:bank') {
                            return <InputDaDataWrapper
                                isVisible={field.type?.title !== 'hidden'}
                                errors={_getErrors(field.projectField.name)}
                                key={field.id}
                                id={field.projectField.name}
                                register={register}
                                readOnly={field.type?.title === 'readonly'}
                                disabled={field.type?.title === 'disabled'}
                                setValue={setValue}
                                isDatetime={false}
                                widget={'bank'}
                                label={field.projectField.label} isRequired={true}/>
                        }
                        if (field.projectField.widget[0]?.fieldWidget?.title === 'DaData:address') {
                            return <InputDaDataWrapper
                                isVisible={field.type?.title !== 'hidden'}
                                errors={_getErrors(field.projectField.name)}
                                key={field.id}
                                id={field.projectField.name}
                                register={register}
                                readOnly={field.type?.title === 'readonly'}
                                disabled={field.type?.title === 'disabled'}
                                setValue={setValue}
                                isDatetime={false}
                                widget={'address'}
                                label={field.projectField.label} isRequired={true}/>
                        }
                        if (field.projectField.projectFieldType.name === 'textarea') {
                            return <TextAreaWrapper
                                isVisible={field.type?.title !== 'hidden'}
                                errors={_getErrors(field.projectField.name)}
                                key={field.id}
                                id={field.projectField.name}
                                register={register}
                                readOnly={field.type?.title === 'readonly'}
                                disabled={field.type?.title === 'disabled'}
                                label={field.projectField.label}/>
                        }

                        if (field.projectField.projectFieldType.name === 'dropdown') {
                            let options = field.projectField.dropdownVal.map(item => ({
                                value: item.key,
                                label: item.val,
                            }));

                            return <SelectWithFormHook
                                key={field.id}
                                isVisible={field.type?.title !== 'hidden'}
                                attribute={"Deal." + field.projectField.name}
                                control={control}
                                readOnly={field.type?.title === 'readonly'}
                                disabled={field.type?.title === 'disabled'}
                                errorMessage={_getErrors(field.projectField.name)?.message}
                                value={_getValueForDropdown(options, field.projectField.name)}
                                setValue={(newValue) => {
                                    setValue("Deal." + field.projectField.name, newValue.value)
                                }}
                                options={options}
                                isRequired={true}
                                requiredMessage={'Необходимо выбрать ' + field.projectField.label}
                                label={field.projectField.label}
                                placeholder={field.projectField.label}
                                onChangeCallback={() => {
                                }}/>
                        }

                        if (field.projectField.widget[0]?.fieldWidget?.title === 'inputMask:ru:date') {
                            return renderInputMaskWrapper(field, '99.99.9999');
                        }

                        for (const {title, mask} of inputMaskFields) {
                            if (field.projectField.widget[0]?.fieldWidget?.title === title) {
                                return renderInputMaskWrapper(field, mask);
                            }
                        }

                        if (field.projectField.projectFieldType.name === 'file-jquery') {
                            return <StyledDropzone
                                text={'Загрузите Документ'}
                                deal={deal}
                                readonly={field.type?.title === 'readonly' || field.type?.title === 'disabled'}
                                token={user.token}
                                clearErrors={clearErrors}
                                onSuccessUpload={() => fetchDeal(deal.id)}
                                key={field.id} blockType={field.projectField.id}
                                projectName={field.projectField.name}/>;
                        }

                        if (field.projectField.projectFieldType.name === 'sms-confirmation') {
                            return <DealVerification deal={deal} key={field.id} stamp={base64ImageStamp}/>
                        }

                        return <InputWrapper
                            isVisible={field.type?.title !== 'hidden'}
                            errors={_getErrors(field.projectField.name)}
                            key={field.id}
                            id={field.projectField.name}
                            register={register}
                            readOnly={field.type?.title === 'readonly'}
                            disabled={field.type?.title === 'disabled'}
                            setValue={setValue}
                            isDatetime={false}
                            label={field.projectField.label} isRequired={true}/>
                    })}

                    {col.buttons.map(button => {
                        if (!isDocumentGenerating) {
                            return (
                                <a onClick={() => _downloadFile(findReplaceString(button.projectButton.path_url, deal.id), button)}
                                   className={button.projectButton.class} key={button.id}
                                   dangerouslySetInnerHTML={{__html: button.projectButton.name}}>
                                </a>
                            );
                        } else if (currentGeneratedDocumentId === button.id) {
                            return (
                                <div className="spinner-border text-primary" key={button.id} role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            );
                        }
                    })}
                    {col.textblocks.map(block => {
                        return (
                            <>
                                <div key={block.id} className={block.projectTextblock.css_class}
                                     dangerouslySetInnerHTML={{__html: block.projectTextblock.text}}/>
                                <br/>
                            </>
                        );
                    })}
                </div>
            );
        })
    }

    if (errorForm.code) {
        return <ErrorComponent message={errorForm.message} code={errorForm.code}/>
    }

    function showModal() {
        let myModal = new Modal(document.getElementById('cancelModal'), {
            keyboard: false
        });
        myModal.show();
    }

    function cancelDeal() {
        setIsLoading(true)
        axiosInstance.post(API_URL + '/deals/cancel', {
            Deal: {
                project_workflow_status_id: backStatusId,
            }
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            params: {
                id: deal.id,
            }
        })
            .then(response => {
                navigate(`/deal/${deal.id}/update`)
            })
            .then(() => {
                let myModal = Modal.getInstance(document.getElementById('cancelModal'));
                myModal.hide();
            })
            .catch(errorResponse => {
                if (errorResponse?.response?.data) {
                    errorResponse.response.data.errors.map(error => {
                        console.log(`Deal.${error.name}`)
                        setError(`Deal.${error.name}`, {
                            type: "random",
                            message: error.message,
                        })
                    })
                }
            }).finally(() => fetchDeal(deal.id))
    }

    const _blockAccordionClasses = () => {
        console.log(pwst['frontend:deal:update:card:accordion'])
        return pwst['frontend:deal:update:card:accordion'] ? 'collapse' : '';
    }

    return (
        <div className="bg-light p-lg-5 rounded">
            <h1>Редактировать #{deal.id}<small className={'ms-md-3'}><span
                className={getBadgeType(deal.statusOptions?.label)}>{deal.status}</span></small></h1>
            <div className={'row'}>
                <div className="col-12 col-md-3 col-lg-2 d-none d-md-block">
                    <Timeline deal={deal} projectStatuses={projectStatuses}/>
                </div>
                <div className="col-12 col-md-9 col-lg-8 d-block">
                    <div className="card mb-3">
                        <div className="card-header">
                            <h5 className="card-title">Информация о сделке</h5>
                        </div>
                        <div className="card-body">
                            <DetailView deal={deal} successCallback={() => {
                                fetchDeal(id)
                            }}/>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(handleForm)} className="justify-content-center">
                        {detail.statusMetadata && detail.statusMetadata.map(metaData => (
                            <AccordionCard
                                key={metaData.id}
                                metaData={metaData}
                                isAccordionEnabled={pwst['frontend:deal:update:card:accordion'] === "1"}
                                renderRows={_renderRows}
                            />
                        ))}
                        {isLoading && (
                            <>
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        {!isLoading && (
                            <>
                                {backButton?.id && (
                                    <a className="btn btn-secondary m-1"
                                       onClick={cancel}>{backButton.value ?? 'Назад'}</a>)}
                                {nextButton?.id && (
                                    <button type={'submit'}
                                            className="btn btn-primary m-1">{nextButton.value ?? 'Вперед'}</button>
                                )}
                                {cancelButton?.id && (
                                    <a className="btn btn-danger m-1"
                                       onClick={showModal}>{cancelButton.value ?? 'Отмена'}</a>
                                )}
                            </>
                        )}
                    </form>
                    <div className={'mt-4'}>
                        {errorList.map((error, index) => (
                            <div key={index} className="alert alert-danger">
                                {error}
                            </div>
                        ))}
                    </div>
                    <div className="modal fade" id="cancelModal" tabIndex="-1" aria-labelledby="cancelModalLabel"
                         aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="cancelModalLabel">Отмена изменений</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    Вы действительно уверены, что хотите изменить статус сделки
                                    на "Отмена"?
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary"
                                            data-bs-dismiss="modal">Закрыть
                                    </button>
                                    <button type="button" className="btn btn-danger" onClick={cancelDeal}>Отменить
                                        изменения
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    user: state.user,
    deal: state.deal,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DealUpdateScreen);
