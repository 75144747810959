import React from 'react';

const PriceTable = ({price}) => {
    if (!price.price_autopolza_format) return null;

    return (
        <>
            <br/>
            <h6>Наполнение:</h6>
            <table className="table">
                <thead>
                <tr>
                    <th>#</th>
                    <th scope="col">Тип цены</th>
                    <th scope="col">Сумма</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>1.</td>
                    <td>Юридический помощник</td>
                    <td>{price.price_vector_format}</td>
                </tr>
                <tr>
                    <td>2.</td>
                    <td>Автопольза Стандарт</td>
                    <td>{price.price_autopolza_format}</td>
                </tr>

                </tbody>
            </table>
        </>
    );
};

export default PriceTable;
