import React, { useEffect } from 'react';
import { FaRegEdit } from "react-icons/fa";
import DealCreateForm from "./DetailCreateForm";
import { Modal } from 'bootstrap';

const DetailView = ({ deal, successCallback = () => {} }) => {
    const [isEdit, setIsEdit] = React.useState(false);

    // При открытии модального окна
    const handleModalShow = () => {
        setIsEdit(true);
    };

    // При закрытии модального окна
    const handleModalHide = () => {
        setIsEdit(false);
        successCallback();
    };

    useEffect(() => {
        const modalElement = document.getElementById('editDealModal');
        if (modalElement) {
            modalElement.addEventListener('show.bs.modal', handleModalShow);
            modalElement.addEventListener('hidden.bs.modal', handleModalHide);
        }
        return () => {
            if (modalElement) {
                modalElement.removeEventListener('show.bs.modal', handleModalShow);
                modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
            }
        };
    }, [deal.id]);

    // Функция для программного закрытия модального окна
    const closeModal = () => {
        const modalElement = document.getElementById('editDealModal');
        if (modalElement) {
            let modalInstance = Modal.getInstance(modalElement);
            if (!modalInstance) {
                modalInstance = new Modal(modalElement);
            }
            modalInstance.hide();
        }
    };

    if (!deal.id) {
        return null;
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="col-12 d-flex">
                    <div style={{ marginRight: '1rem' }}>
                        <b>Номер сертификата:</b>
                        <br className="d-block d-md-none" /> {deal.contractCode}
                        {deal.is_test && (
                            <>
                                <br className="d-block d-md-none" />
                                <span className="badge bg-warning mx-3">Тестовая сделка</span>
                            </>
                        )}
                    </div>
                    {(deal.dealInfo.beginAt || deal.dealInfo.endAt) && (
                        <div style={{ marginRight: '1rem' }}>
                            <b>Действует: </b>
                            {deal.dealInfo.beginAt ? 'с ' + deal.dealInfo.beginAt : ''}
                            {deal.dealInfo.endAt ? ' по ' + deal.dealInfo.endAt : ''}
                        </div>
                    )}
                    <a
                        href={`/deal/${deal.id}/update`}
                        className="btn btn-outline-primary btn-sm ms-auto"
                        data-bs-toggle="modal"
                        data-bs-target="#editDealModal"
                        aria-label="Edit deal"
                    >
                        <FaRegEdit />
                    </a>
                </div>
            </div>

            {/* Верстка для мобильного устройства */}
            <div className="col-12 d-block d-md-none">
                <hr />
                <div className="col-12 d-inline-block">
                    <div className="pe-3">
                        <b>Проект:</b> {deal.dealInfo.project.title}
                    </div>
                    <div className="pe-3">
                        <b>Продукт:</b> {deal.dealInfo.project.product.title}
                    </div>
                </div>
                <div className="col-12 d-inline-block">
                    <div className="pe-3">
                        <b>Тариф:</b> {deal.dealInfo.project.price.title}
                    </div>
                    <div className="pe-3">
                        <b>Стоимость:</b> {deal.dealInfo.price} {deal.dealInfo.currency}
                    </div>
                </div>
                <hr />
                <div className="col-12 d-inline-block">
                    <div className="pe-3">
                        <b>Подразделение:</b> {deal.dealInfo.organization.title}
                    </div>
                    <div className="pe-3">
                        <b>Пользователь:</b> {deal.dealInfo.user.title}
                    </div>
                </div>
                <hr />
                <div className="col-12 d-inline-block">
                    <div className="pe-3">
                        <b>Клиент ФИО:</b> {deal.dealInfo.client_fio}
                    </div>
                    <div className="pe-3">
                        <b>Телефон:</b> {deal.dealInfo.client_phone}
                    </div>
                    <div className="pe-3">
                        <b>Email:</b> {deal.dealInfo.client_email}
                    </div>
                </div>
            </div>

            {/* Верстка для десктопной версии */}
            <div className="col-12 d-none d-md-block">
                <hr />
                <div className="col-12 d-flex">
                    <div className="pe-3">
                        <b>Проект:</b> {deal.dealInfo.project.title}
                    </div>
                    <div className="pe-3">
                        <b>Продукт:</b> {deal.dealInfo.project.product.title}
                    </div>
                </div>
                <div className="col-12 d-flex">
                    <div className="pe-3">
                        <b>Тариф:</b> {deal.dealInfo.project.price.title}
                    </div>
                    <div className="pe-3">
                        <b>Стоимость:</b> {deal.dealInfo.price} {deal.dealInfo.currency}
                    </div>
                </div>
                <hr />
                <div className="col-12 d-flex">
                    <div className="pe-3">
                        <b>Подразделение:</b> {deal.dealInfo.organization.title}
                    </div>
                    <div className="pe-3">
                        <b>Пользователь:</b> {deal.dealInfo.user.title}
                    </div>
                </div>
                <hr />
                <div className="col-12 d-flex">
                    <div className="pe-3">
                        <b>Клиент ФИО:</b> {deal.dealInfo.client_fio}
                    </div>
                    <div className="pe-3">
                        <b>Телефон:</b> {deal.dealInfo.client_phone}
                    </div>
                    <div className="pe-3">
                        <b>Email:</b> {deal.dealInfo.client_email}
                    </div>
                </div>
            </div>

            {/* Модальное окно */}
            <div
                className="modal fade"
                id="editDealModal"
                tabIndex="-1"
                aria-labelledby="editDealModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editDealModalLabel">
                                Изменить тариф
                            </h5>
                            <button
                                type="button"
                                id="closeEditDealModal"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                {isEdit && (
                                    <DealCreateForm
                                        dealModel={deal}
                                        successCallback={closeModal}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailView;
