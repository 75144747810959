import React from 'react';

const AccordionCard = ({metaData, isAccordionEnabled, renderRows}) => {
    const cardHeaderProps = isAccordionEnabled ? {
        'data-bs-toggle': "collapse",
        'aria-expanded': "true",
        'aria-controls': 'collapseOne' + metaData.id,
        'data-bs-target': '#collapseOne' + metaData.id,
        style: {cursor: 'pointer'}
    } : {};

    const cardBodyProps = isAccordionEnabled ? {
        className: `${metaData.css_class} collapse`,
        'data-bs-parent': "#accordionCard",
        'aria-labelledby': 'headingOne' + metaData.id,
        id: 'collapseOne' + metaData.id
    } : {};

    return (
        <div className={isAccordionEnabled ? "accordion" : ""} id="accordionCard">
            <div className="card" style={{marginBottom: '20px'}} key={metaData.id}>
                <div className="card-header">
                    <h5 className="card-title" {...cardHeaderProps}>
                        {metaData.name}
                    </h5>
                </div>
                <div {...cardBodyProps}>
                    <div className="card-body" id={metaData.id}>
                        {renderRows(metaData.row)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccordionCard;
