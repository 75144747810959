import React, {useCallback, useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Timeline from "../../components/timeline/Timeline";
import DealCreateForm from "../../components/deal/DetailCreateForm";
import axiosInstance from "../../utils/apiCalls";
import {API_URL} from "../../utils/consts";


function DealCreateScreen() {
    const [deal, setDeal] = useState({});

    const loadDeal = useCallback((id) => {
        fetchDeal(id)
    }, []);

    const fetchDeal = (id) => {
        axiosInstance.get(API_URL + '/deals/detail', {
            params: {
                id,
            }
        })
            .then(response => response.data)
            .then(response => {
                setDeal(response.data)
            })
            .catch(errorResponse => {
                console.log({
                    message: errorResponse.response.data.errors.message,
                    code: errorResponse.response.status
                })
            })
    }


    return (
        <div className="bg-light p-lg-5 rounded">
            <h1>Создание сделки</h1>
            <div className="row">
                <div className="col-lg-4">
                    <Timeline deal={null}/>
                </div>
                <div className="col-12 col-lg-4">
                <DealCreateForm />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DealCreateScreen);
